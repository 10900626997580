import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";

// 我的会员卡 列表
export const getMemberList = (data?:any): Promise<AxiosResponse<object>> =>{
    let url = '/agent-api/api/member/index?';
    let params = ''
    for (const key in data) {
        params += `${data[key]!= '' && data[key]!= null ?key:''}=${data[key]!= '' && data[key]!= null ?data[key]:''}&`
    }
    
    return service({
        method:'get',
        url:`${api}${url}${params}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

//我的会员导出
export const memberExport = (): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/member/export`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 获取二维码地址
export const getCode = (): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/my_qrcode`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 个人设置 查找个人信息
export const getUserInfo = (data:object): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/get_user_info`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 个人设置修改密码
export const modifyPassword = (data:object): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/save_user_info`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}