 
// 我的二维码页面
import { defineComponent ,ref ,reactive, toRefs, onMounted, onBeforeMount} from 'vue';
import { getCode } from '@/api/personal';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
const key = 'updatable';
export default defineComponent({
    name:"code",
    components: {
        breadcrumb
    },
    setup(){
        // 面包屑
        const breadData = ref<Array<object>>([
            {title:'首页',path:'#/'},
            {title:'个人',path:'/index'},
            {title:'我的二维码',path:'#/'},
        ])
        const imgUrl = ref<string>('');
        const cardList = ref({})
        onBeforeMount(()=>{
            let role_id = localStorage.getItem('userInfo')
            role_id = JSON.parse(role_id as string)
            if((role_id as any).role_id == 5){
                breadData.value.splice(2,1)
            }
            getCode().then((res:any)=>{
                if(res.code == 200){
                    imgUrl.value = res.data.qrcode_image;
                    cardList.value = res.data.exper_qrcode;
                }
            })
        })
        onMounted(()=>{
        })
        return{
            imgUrl,
            breadData,
            cardList
        }
    }
})
